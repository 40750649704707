String.prototype.includes = function(match) {
  return this.indexOf(match) !== -1;
};

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function(searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}

if (
  document.readyState === 'complete' ||
  document.readyState === 'interactive'
) {
  // call on next available tick
  setTimeout(checkAgentAvailability, 1);
} else {
  document.addEventListener('DOMContentLoaded', checkAgentAvailability);
}

/**
 * Checks whether agents are available before initializing the chat.
 *
 * ```json
 * {"agents-available": true, "free-capacity": true}
 * ```
 */
function checkAgentAvailability() {
  var request = new XMLHttpRequest();

  request.addEventListener('load', function (res) {
    var data = JSON.parse(this.responseText);

    if (data['agents-available'] === true && data['free-capacity'] === true) {
        replyChat();
    }
  });

  request.open('GET', REPLY_CHAT_AGENTS_AVAILABLE_LINK);
  request.send();
}

/**********************************************************************************************************************
 *                                                            Reply Chat                                              *
 * This file will manipulate the DOM-THREE after the body is rendered. It will display a chat-button which will offer *
 * a chat displayed in an i-frame. The i-frame is wrapped by a frame which can be dragged over the whole window.      *
 *                                                                                                                    *
 **********************************************************************************************************************/

/******************************************************** GLOBAL VARIABLES ********************************************/

/*************************************************************
 **                          Settings                       **
 *************************************************************/

var REPLY_CHAT_IFRAME_LINK = 'https://chat.badenova.de:8443/chat/?channel-id=c54c930e-7175-42ec-a297-9727a5cf729b&tenant-id=1f3ef60c-a267-33ee-a740-062e77a31232&system-id=c012378f-21c4-4a3b-bdc0-6479024046fc&language=es&theme-name=freunde';
  REPLY_CHAT_IFRAME_LINK !== undefined
    ? REPLY_CHAT_IFRAME_LINK
    : 'http://localhost:42003/customer-chat';
var REPLY_CHAT_DRAG_ENABLED = true;
var REPLY_CHAT_ENTRY_DIV_ID = null;
var REPLY_CHAT_AGENTS_AVAILABLE_LINK = 'https://chat.badenova.de:8443/agentsAvailable/c012378f-21c4-4a3b-bdc0-6479024046fc/c54c930e-7175-42ec-a297-9727a5cf729b';

/*************************************************************
 **  Variant: show different iframe on specific pages       **
 *************************************************************/
var REPLY_CHAT_IFRAME_LINK_VARIANT = 'https://chat.badenova.de:8443/chat/?channel-id=c54c930e-7175-42ec-a297-9727a5cf729b&tenant-id=1f3ef60c-a267-33ee-a740-062e77a31232&system-id=c012378f-21c4-4a3b-bdc0-6479024046fc&language=es&theme-name=freunde';
var REPLY_CHAT_AGENTS_AVAILABLE_LINK_VARIANT = 'https://chat.badenova.de:8443/agentsAvailable/c012378f-21c4-4a3b-bdc0-6479024046fc/c54c930e-7175-42ec-a297-9727a5cf729b';
var REPLY_CHAT_OPEN_TIME_VARIANT = 10; // time in seconds
var REPLY_CHAT_MOBILE_TEXT_VARIANT = '<button class="icon-cross_circle reply-chat-mobile-info-close"></button> Fragen zu unseren Produkten? Rufen Sie uns einfach unter der <a href="tel:08002791010">0800 279 1010</a> an oder klicken Sie hier um mit uns zu chatten.';
var REPLY_CHAT_URL_ARRY_VARIANT = [];
var REPLY_CHAT_IS_VARIANT = false; // don't change the variable! it will be overwritten if url array matches the url!
var REPLY_CHAT_WAS_OPEN = false;

//overwirte REPLY_CHAT_IS_VARIANT if url matches the url array
REPLY_CHAT_IS_VARIANT = REPLY_CHAT_URL_ARRY_VARIANT.some(function(v) { return window.location.href.indexOf(v) >= 0 }) ? true : false;
REPLY_CHAT_IFRAME_LINK = REPLY_CHAT_IS_VARIANT ? REPLY_CHAT_IFRAME_LINK_VARIANT : REPLY_CHAT_IFRAME_LINK;
REPLY_CHAT_AGENTS_AVAILABLE_LINK = REPLY_CHAT_IS_VARIANT ? REPLY_CHAT_AGENTS_AVAILABLE_LINK_VARIANT : REPLY_CHAT_AGENTS_AVAILABLE_LINK;

/*************************************************************
 **                          Text                           **
 *************************************************************/

var REPLY_CHAT_CHAT_BUBBLE_TEXT = 'JETZT MIT UNS CHATTEN';
var REPLY_CHAT_CHAT_CANCEL_TEXT = 'Zurück';
var REPLY_CHAT_CHAT_OK_TEXT = 'Fenster schließen';

/******************************************************** REPLY CHAT FUNCTION ********************************************/

function replyChat() {
  /*************************************************************
   **                          CSS-Classes                    **
   *************************************************************/

  const CLASS_NAME_REPLY_CHAT_CHAT_BUBBLE = 'reply-chat-chat-bubble';
  const CLASS_NAME_REPLY_CHAT_CHAT_BUBBLE_LABLE =
    'reply-chat-chat-bubble-label';
  const CLASS_NAME_REPLY_CHAT_CHAT_BUBBLE_REMOVED_FROM_DOM =
    'reply-chat-chat-bubble-removed-from-DOM';
  const CLASS_NAME_REPLY_CHAT_CHAT_FRAME = 'reply-chat-chat-frame';
  const CLASS_NAME_REPLY_CHAT_CHAT_FRAME_HIDDEN =
    'reply-chat-chat-frame-hidden';
  const CLASS_NAME_REPLY_CHAT_CHAT_FRAME_HEADER =
    'reply-chat-chat-frame-header';
  const CLASS_NAME_REPLY_CHAT_CHAT_FRAME_HEADER_LOGO =
    'reply-chat-chat-frame-header-logo';
  const CLASS_NAME_REPLY_CHAT_CHAT_FRAME_HEADER_CLOSE_BUTTON =
    'reply-chat-chat-frame-header-close-button';
  const CLASS_NAME_REPLY_CHAT_CHAT_FRAME_BEFORE_CLOSE_OVERLAY =
    'reply-chat-chat-frame-before-close-overlay';
  const CLASS_NAME_REPLY_CHAT_CHAT_FRAME_BEFORE_CLOSE_OVERLAY_OK =
    'reply-chat-chat-frame-before-close-overlay-ok';
  const CLASS_NAME_REPLY_CHAT_CHAT_FRAME_BEFORE_CLOSE_OVERLAY_CANCEL =
    'reply-chat-chat-frame-before-close-overlay-cancel';
  const CLASS_NAME_REPLY_CHAT_CHAT_FRAME_IFRAME =
    'reply-chat-chat-frame-iframe';

  /*************************************************************
   **                          Variables                      **
   *************************************************************/

  var replyChatChatBubble;
  var replyChatChatFrame;
  var replyChatChatFrameHeader;
  var replyChatBeforeCloseOverlay;
  var replyChatChatBeforeCloseOverlayIsHidden = true;

  /*************************************************************
   **                          Constants                      **
   *************************************************************/
  const REPLY_CHAT_IFRAME_ID = 'reply-chat-embedded-Iframe';
  const REPLY_CHAT_FRAME_ID = 'reply-chat-embedded-frame';
  const REPLY_CHAT_BUBBLE_ID = 'reply-chat-embedded-bubble';

  /**
   * This method will be called right after the document is load.
   */
   cookieTest('https://chat.badenova.de:8443/chat/assets/white_label/html/cookieTest.html', function(result) { 
    if(result) {
        init();
      }
  });

  /**
   * This method will initialize reply chat.
   */
  function init() {
    initReset();
    initPostMessageListener();
  }

  /**
   * Will reset this script to its inital state.
   */
  function initReset() {
    const hidden = false;
    removeChatFromWebsite();
    addChatBubbleToDOM(createChatBubbleElement(hidden));
  }

  function reset() {
    const hidden = true;
    removeChatFromWebsite();
    addChatBubbleToDOM(createChatBubbleElement(hidden));
    addChatFrameToDOM(createChatFrameElement(hidden));
  }

  /**
   * Removes all visible parts from this website.
   */
  function removeChatFromWebsite() {
    var entryDiv = REPLY_CHAT_ENTRY_DIV_ID
      ? document.getElementById(REPLY_CHAT_ENTRY_DIV_ID)
      : document.body;
    if (replyChatChatBubble) {
      entryDiv.removeChild(replyChatChatBubble);
    }
    if (replyChatChatFrame) {
      entryDiv.removeChild(replyChatChatFrame);
    }
  }

  /**
  * check for iframe cookie
   */

  function cookieTest(iFrameUri, callBack) {

    let messageHandler = (event) => {
        // check for trusted origins here         
        const data = JSON.parse(event.data)
        callBack(data['result'])
        window.removeEventListener('message', messageHandler);
        document.body.removeChild(frame)
    }
    window.addEventListener('message', messageHandler);
    const frame = document.createElement('iframe')
    frame.src = iFrameUri
    frame.sandbox = "allow-scripts allow-same-origin"
    frame.style = `display:none`
    frame.onload = (e) => {
        frame.contentWindow.postMessage(JSON.stringify({ 'test': 'cookie' }), '*');
    }
    document.body.appendChild(frame)
}

  /**
   * Call this method to switch between bubble and frame
   */
  function switchChat() {
    if (!isReplyChatBubbleVisible()) {
      replyChatChatBeforeCloseOverlayIsHidden = true;
      closeChatFrame();
      showChatBubble();
    } else {
      hideChatBubble();
      const iframe = document.getElementById(REPLY_CHAT_IFRAME_ID);
          iframe.contentWindow.postMessage(
            { id: 'REPLY_START_NEW_CHAT_MESSAGE' },
             '*'
          );
      showChatFrame();

    }
  }

  /**
   * This method will display the chat-bubble.
   * NOTE: Call switchChat instad
   */
  function showChatBubble() {
    if (replyChatChatBubble && !isReplyChatBubbleVisible()) {
      replyChatChatBubble.classList.remove(
        CLASS_NAME_REPLY_CHAT_CHAT_BUBBLE_REMOVED_FROM_DOM
      );
    }
  }

  /**
   * This method will display the chat-frame.
   * NOTE: Call switchChat instad
   */
  function showChatFrame() {
    if (replyChatChatFrame && !isReplyChatFrameVisible()) {
      replyChatChatFrame.classList.remove(
        CLASS_NAME_REPLY_CHAT_CHAT_FRAME_HIDDEN
      );
    }
  }

  /**
   * This method will hide the chat-bubble.
   * NOTE: Call switchChat instad
   */
  function hideChatBubble() {
    if (replyChatChatBubble && isReplyChatBubbleVisible()) {
      replyChatChatBubble.classList.add(
        CLASS_NAME_REPLY_CHAT_CHAT_BUBBLE_REMOVED_FROM_DOM
      );
    }
  }

  /**
   * This method will hide the chat-frame.
   * NOTE: Call switchChat instad
   */
  function closeChatFrame() {
    const iframe = document.getElementById(REPLY_CHAT_IFRAME_ID);
    iframe.contentWindow.postMessage({ id: 'REPLY_CLOSE_CLIENT_MESSAGE' }, '*');
  }

  /**
   * Will add the bubble to the DOM.
   * @param  chatBubbleElement
   */
  function addChatBubbleToDOM(chatBubbleElement) {
    replyChatChatBubble = chatBubbleElement;
    if (REPLY_CHAT_ENTRY_DIV_ID) {
      document
        .getElementById(REPLY_CHAT_ENTRY_DIV_ID)
        .appendChild(replyChatChatBubble);
    } else {
      document.body.appendChild(replyChatChatBubble);
    }
  }

  /**
   * Will add the frame to the DOM.
   * @param  chatBubbleElement
   */
  function addChatFrameToDOM(chatChatFrameElement) {
    replyChatChatFrame = chatChatFrameElement;
    if (REPLY_CHAT_ENTRY_DIV_ID) {
      document
        .getElementById(REPLY_CHAT_ENTRY_DIV_ID)
        .appendChild(replyChatChatFrame);
    } else {
      document.body.appendChild(replyChatChatFrame);
    }
    if (REPLY_CHAT_DRAG_ENABLED) {
      dragElement();
    }
  }

  /**
   * This function returns true only if the chat-bubble is visible to the user
   */
  function isReplyChatBubbleVisible() {
    let chatBubble = document.getElementById(REPLY_CHAT_BUBBLE_ID);
    return chatBubble && !chatBubble.classList.contains(CLASS_NAME_REPLY_CHAT_CHAT_BUBBLE_REMOVED_FROM_DOM);
  }

  /**
   * This function returns true only if the chat-frame is visible to the user
   */
  function isReplyChatFrameVisible() {
    let chatFrame = document.getElementById(REPLY_CHAT_FRAME_ID);
    return chatFrame && !chatFrame.classList.contains(CLASS_NAME_REPLY_CHAT_CHAT_FRAME_HIDDEN);
  }

  /**
   * Will create a chat bubble element.
   */
  function createChatBubbleElement(hidden) {
    var chatBubble = document.createElement('div');
    chatBubble.id = REPLY_CHAT_BUBBLE_ID;
    var chatBubbleLable = document.createElement('div');
    chatBubble.classList.add(CLASS_NAME_REPLY_CHAT_CHAT_BUBBLE);


    chatBubbleLable.classList.add(CLASS_NAME_REPLY_CHAT_CHAT_BUBBLE_LABLE);
    chatBubbleLable.innerHTML = REPLY_CHAT_CHAT_BUBBLE_TEXT;
    chatBubble.addEventListener('click', function() {

      REPLY_CHAT_WAS_OPEN = true;
      if(document.querySelector('#reply-chat-embedded-frame') === null) {
          addChatFrameToDOM(createChatFrameElement(true));
          hideChatBubble();
          const iframe = document.getElementById(REPLY_CHAT_IFRAME_ID);
          iframe.onload = function() {
            setTimeout(function() {
              iframe.contentWindow.postMessage(
                { id: 'REPLY_START_NEW_CHAT_MESSAGE' },
                 '*'
              );
              chatBubble.classList.add(
                  CLASS_NAME_REPLY_CHAT_CHAT_BUBBLE_REMOVED_FROM_DOM
              );
            },500);
          };
          if(document.querySelector('.reply-chat-mobile-info') !== null) {
            document.querySelector('.reply-chat-mobile-info').classList.add('removed');
              setTimeout(() => {
                document.querySelector('.reply-chat-mobile-info').remove();
            }, 1000);
          }
          showChatFrame();
      } else {
        switchChat();
      }
    });
    if(REPLY_CHAT_IS_VARIANT) {
      var runInfoBubble = function() {
            if(!REPLY_CHAT_WAS_OPEN) {
              if(window.outerWidth <= 768) {
                if(document.querySelector('#reply-chat-embedded-frame') === null) {
                  var mobileElement = document.createElement('div');
                  mobileElement.classList.add('reply-chat-mobile-info');
                  mobileElement.innerHTML = REPLY_CHAT_MOBILE_TEXT_VARIANT;
                  document.body.appendChild(mobileElement);

                  mobileElement.querySelector('.reply-chat-mobile-info-close').addEventListener('click', () => {
                    document.querySelector('.reply-chat-mobile-info').classList.add('removed');
                    setTimeout(() => {
                      document.querySelector('.reply-chat-mobile-info').remove();
                    },1000);
                  });
                }
              } else {
                if(document.querySelector('#reply-chat-embedded-frame') === null) {
                    addChatFrameToDOM(createChatFrameElement(true));
                    hideChatBubble();
                    const iframe = document.getElementById(REPLY_CHAT_IFRAME_ID);
                    iframe.onload = function() {
                      setTimeout(function() {
                        iframe.contentWindow.postMessage(
                          { id: 'REPLY_START_NEW_CHAT_MESSAGE' },
                          '*'
                        );
                        chatBubble.classList.add(
                          CLASS_NAME_REPLY_CHAT_CHAT_BUBBLE_REMOVED_FROM_DOM
                      );
                      },500);
                    };
                    showChatFrame();
                } else {
                  switchChat();
                }
              }
              REPLY_CHAT_WAS_OPEN = true;
          }
      };
      var resetNoMovement = function() {
        noActionTimer = REPLY_CHAT_OPEN_TIME_VARIANT;
      }
      var noActionTimer = REPLY_CHAT_OPEN_TIME_VARIANT;
      document.addEventListener('keydown', resetNoMovement);
      document.addEventListener('click', resetNoMovement);

      var noMovement = function() {
        if (noActionTimer === 0) {
          clearInterval(noActionInterval);
          document.removeEventListener('keydown', resetNoMovement);
          document.removeEventListener('click', resetNoMovement);
          runInfoBubble();
        } else {
          noActionTimer--;
        }
      }
      var noActionInterval = setInterval(function(){noMovement()}, 1000);
    }


    chatBubble.appendChild(chatBubbleLable);
    return chatBubble;
  }

  /**
   * Will create a chat frame element.
   */
  function createChatFrameElement(hidden) {
    var chatFrame = document.createElement('div');
    chatFrame.id = REPLY_CHAT_FRAME_ID;
    chatFrame.classList.add(CLASS_NAME_REPLY_CHAT_CHAT_FRAME);
    chatFrame.classList.add('dock');
    if (hidden) {
      chatFrame.classList.add(CLASS_NAME_REPLY_CHAT_CHAT_FRAME_HIDDEN);
    }
    replyChatChatFrameHeader = createChatFrameHeaderElement();
    chatFrame.appendChild(replyChatChatFrameHeader);
    var iframe = document.createElement('iframe');
    iframe.classList.add(CLASS_NAME_REPLY_CHAT_CHAT_FRAME_IFRAME);
    iframe.frameBorder = 0;
    iframe.id = REPLY_CHAT_IFRAME_ID;
    iframe.setAttribute(
      'src',
      REPLY_CHAT_IFRAME_LINK.includes('?')
        ? REPLY_CHAT_IFRAME_LINK + '&embedded=true'
        : REPLY_CHAT_IFRAME_LINK + '?embedded=true'
    );
    //iframe.classList.add();
    chatFrame.appendChild(iframe);
    return chatFrame;
  }

  /**
   * Creates the header of the chat-frame
   */
  function createChatFrameHeaderElement() {
    var chatFrameHeader = document.createElement('div');
    chatFrameHeader.classList.add(CLASS_NAME_REPLY_CHAT_CHAT_FRAME_HEADER);
    chatFrameHeader.classList.add(CLASS_NAME_REPLY_CHAT_CHAT_FRAME_HEADER_LOGO);
    var closeButton = document.createElement('div');
    closeButton.classList.add(
      CLASS_NAME_REPLY_CHAT_CHAT_FRAME_HEADER_CLOSE_BUTTON
    );

    // NOTE: Skip the overlay before closing the chat, close it right away
    // closeButton.addEventListener('click', switchBeforeCloseOverlay);
    closeButton.addEventListener('click', switchChat);

    closeButton.innerHTML = '&#x2716;';
    chatFrameHeader.appendChild(closeButton);
    return chatFrameHeader;
  }

  /**
   * will init the replyChatBeforeCloseOverlay variable on top of the document.
   */
  function addBeforeCloseOverlay() {
    var chatFrameBeforeCloseOverlay = document.createElement('div');
    chatFrameBeforeCloseOverlay.classList.add(
      CLASS_NAME_REPLY_CHAT_CHAT_FRAME_BEFORE_CLOSE_OVERLAY
    );
    var okButton = document.createElement('div');
    okButton.addEventListener('click', switchChat);
    okButton.classList.add(
      CLASS_NAME_REPLY_CHAT_CHAT_FRAME_BEFORE_CLOSE_OVERLAY_OK
    );
    okButton.innerText = REPLY_CHAT_CHAT_OK_TEXT;
    var cancelButton = document.createElement('div');
    cancelButton.addEventListener('click', switchBeforeCloseOverlay);
    cancelButton.classList.add(
      CLASS_NAME_REPLY_CHAT_CHAT_FRAME_BEFORE_CLOSE_OVERLAY_CANCEL
    );
    cancelButton.innerText = REPLY_CHAT_CHAT_CANCEL_TEXT;
    chatFrameBeforeCloseOverlay.appendChild(cancelButton);
    chatFrameBeforeCloseOverlay.appendChild(okButton);
    replyChatBeforeCloseOverlay = chatFrameBeforeCloseOverlay;
  }

  function switchBeforeCloseOverlay() {
    if (replyChatChatBeforeCloseOverlayIsHidden) {
      if (!replyChatBeforeCloseOverlay) {
        addBeforeCloseOverlay();
      }
      replyChatChatFrame.appendChild(replyChatBeforeCloseOverlay);
      replyChatChatBeforeCloseOverlayIsHidden = false;
    } else {
      if (replyChatBeforeCloseOverlay) {
        replyChatChatFrame.removeChild(replyChatBeforeCloseOverlay);
        replyChatChatBeforeCloseOverlayIsHidden = true;
      }
    }
  }

  /**
   * This method will initalize the functions which automatically offers dragging of the frame.
   */
  function dragElement() {
    var horizontalDiff = 0,
      verticalDiff = 0,
      horizontalPosition = 0,
      verticalPosition = 0;
    var elmnt = replyChatChatFrame;
    if (replyChatChatFrameHeader && replyChatChatFrameHeader.offsetTop > 0) {
      /* if present, the header is where you move the DIV from:*/
      replyChatChatFrameHeader.onmousedown = dragMouseDown;
      replyChatChatFrameHeader.onfocusout = closeDragElement;
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      horizontalPosition = e.clientX;
      verticalPosition = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:

      horizontalDiff = horizontalPosition - e.clientX;
      verticalDiff = verticalPosition - e.clientY;
      horizontalPosition = e.clientX;
      verticalPosition = e.clientY;

      var saftyPadding = 11;
      // set the element's new position:
      var isBottomOutOfView =
        elmnt.offsetHeight + verticalPosition + saftyPadding >
        window.innerHeight;
      var isTopOutOfView = verticalPosition - saftyPadding < 0;
      if (!isBottomOutOfView && !isTopOutOfView) {
        elmnt.style.top = elmnt.offsetTop - verticalDiff + 'px';
      } else if (isTopOutOfView) {
        elmnt.style.top = saftyPadding + 'px';
      } else if (isBottomOutOfView) {
        elmnt.style.top =
          window.innerHeight - elmnt.offsetHeight - saftyPadding + 'px';
      }
      var isRightOutOfView =
        elmnt.offsetWidth + horizontalPosition + saftyPadding >
        window.innerWidth;
      var isLeftOutOfView = horizontalPosition - saftyPadding < 0;
      if (!isRightOutOfView && !isLeftOutOfView) {
        elmnt.style.left = elmnt.offsetLeft - horizontalDiff + 'px';
      } else if (isRightOutOfView) {
        elmnt.style.left =
          window.innerWidth - elmnt.offsetWidth - saftyPadding + 'px';
      } else if (isLeftOutOfView) {
        elmnt.style.left = saftyPadding + 'px';
      }
    }

    function closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }

  /**
   * This will initialize the communication between the iframe and this website.
   */
  function initPostMessageListener() {
    var eventMethod = window.addEventListener
      ? 'addEventListener'
      : 'attachEvent';
    var eventer = window[eventMethod];
    var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    eventer(messageEvent, function(postMessage) {
      var message;
      if (
        postMessage.origin &&
        !REPLY_CHAT_IFRAME_LINK.startsWith(postMessage.origin)
      )
        return;
      try {
        if (postMessage.data) {
          message = postMessage.data;
        } else {
          message = postMessage.message;
        }
        if (message && message.id) {
          switch (postMessage.data.id) {
            case 'REPLY_CHAT_IS_NEW_CHAT_MESSAGE': {
              onNewChatMessage(message);
              break;
            }
            case 'REPLY_CHAT_IS_RECONNECTED_CHAT_MESSAGE': {
              onReconnectMessage(message);
              break;
            }
            case 'REPLY_CLOSE_CLIENT_MESSAGE': {
              onCloseRequestMessage(message);
              break;
            }
          }
        }
      } catch (e) {}
    });
  }

  /**
   * This message is being called if this script should switch to its inital state or if
   * it should remove all of its visible parts from the website.
   */
  function onCloseRequestMessage(message) {
    if (message.data && message.data.final) {
      removeChatFromWebsite();
    } else {
      reset();
    }
  }

  /**
   * This message informs the website of a new chat.
   */
  function onNewChatMessage(message) {
    showChatBubble();
  }

  /**
   * This message informs the website of a reconnected chat.
   */
  function onReconnectMessage(message) {
    showChatFrame();
  }
}
